import { graphql, navigate, PageProps } from 'gatsby'
import { StaticImage } from 'gatsby-plugin-image'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import RepairContent from '../components/RepairContent'
import RepairSelect, {
  IOption,
  IRepairSelectOption,
} from '../components/RepairSelect'
import RepairSelectContainer from '../components/RepairSelectContainer'
import repairPoints from '../data/repairPoints'
import saveRepairPoints from '../data/saveRepairPoints'
import { RepairsQuery } from '../_generated/codegen/graphqlTypes'
import { castSaveRepairCode } from '../utils/castSaveRepairCode'
import notEmpty from '../utils/notEmpty'
import replacePlusWithWord from '../utils/replacePlusWithWord'
import { Unpacked } from '../utils/Unpacked'
import { RepairModelPageContext } from './RepairModelPage.context'
import uniqBy from 'lodash/uniqBy'
import { useForm } from 'react-hook-form'
import Form from '../components/Form'
import { RepairSelectFormData } from '../models/RepairSelectFormData'
import { yupResolver } from '@hookform/resolvers/yup'
import repairSelectFormSchema from '../validations/repairSelectFormSchema'
import { repairShopPageBuilder } from './RepairShopPage.context'
import fixRepairLabel from '../utils/fixRepairLabel'
import { Helmet } from 'react-helmet'
import useSticky from '../utils/useSticky'
import { useMedia } from 'react-use'
import theme from '../theme/theme'
import formatColorName from '../utils/formatColorName'
import ClientOnly from '../components/Client-only'
import styled from 'styled-components'
import { getProductByUrlCode } from '../data/productTypes'
import RepairOtherSelect from '../components/RepairOtherSelect'
import saveRepairDetails from '../data/saveRepairDetails'
import capitalizeFirstLetterOfWords from '../utils/capitaliseFirstLetter'
import capitalizeFirstLetter from '../utils/capitalizeFirstLetter'
import RepairSummary from "../components/RepairSummary";

const MaxRepSelectionParagraph = styled.p<{ show: boolean }>`
  display: ${(props) => (props.show ? 'flex' : 'none')};
  margin-top: 20px;
  text-align: center;
  animation: color-change 2s 1;
  @keyframes color-change {
    0% {
      color: red;
    }
    100% {
      color: black;
    }
  }
`

const useItems = (data: RepairsQuery): IRepair[] => {
  return useMemo(() => {
    return data.allSaveRepair.nodes
  }, [data])
}

type IRepairOption = NonNullable<
  Unpacked<NonNullable<IRepair['webPricingRepairList']>>
>

const useOptions = (items: IRepair[]): IRepairSelectOption[] => {
  return useMemo(() => {
    return items.map((item) => {
      const optionsRaw =
        item.webPricingRepairList
          ?.filter((x) => !!x?.reference)
          .filter(notEmpty) ?? []

      const code = castSaveRepairCode(item.code)

      const key: keyof IRepairOption = 'reference'
      const optionsUniqByReference = uniqBy(optionsRaw, key)
      const optionsUniqByColor = uniqBy(optionsUniqByReference, (x) => {
        if (x.color?.length) {
          return x.color
        }
        return undefined
      })

      const options: IOption[] = optionsUniqByColor.map((x) => {
        const color =
          (x.colorHex === 'XXX' ? undefined : x.colorHex) ??
          x.color?.toLocaleLowerCase() ??
          undefined

        const label = x.label?.length
          ? x.label
          : color?.length
          ? formatColorName(color)
          : x.reference ?? ''

        return {
          label,
          color: color ?? '#000000',
          value: x.reference ?? '',
        }
      })
      return {
        label: fixRepairLabel(item.code ?? ''),
        code: item.code ?? '',
        options,
        target: code ? saveRepairPoints[code] : repairPoints.SCREEN,
        repairDetail: code ? saveRepairDetails[code] : {},
      }
    })
  }, [items])
}

const RepairModelPage: React.FC<
  PageProps<RepairsQuery, RepairModelPageContext>
> = ({ data, pageContext }) => {
  const items = useItems(data)
  const options = useOptions(items)

  const {
    handleSubmit,
    setValue,
    formState: { errors, isSubmitting },
  } = useForm<RepairSelectFormData>({
    resolver: yupResolver(repairSelectFormSchema),
    shouldUnregister: true,
  })

  const pathBuilder = useCallback(
    (reference: string[]) => {
      let code
      //multi reparation
      if (reference.length > 1) {
        //get code from reference
        const item = items.filter(
          (x) =>
            !!x.webPricingRepairList?.find((y) =>
              reference.find((z) => z === y?.reference)
            )
        )
        code = item.map((x) => x.code).join('+')
        return repairShopPageBuilder(
          pageContext.brandName,
          pageContext.slug,
          code,
          pageContext.productType
        )
      } else {
        code = items.find((x) => {
          return !!x.webPricingRepairList?.find(
            (x) => x?.reference === reference[0]
          )
        })?.code
        if (!code) {
          return '#'
        }
      }

      return repairShopPageBuilder(
        pageContext.brandName,
        pageContext.slug,
        code,
        pageContext.productType
      )
    },
    [items, pageContext]
  )

  const [showMessage, setShowMessage] = useState(false)

  const onSubmit = handleSubmit(async ({ reference }) => {
    const path = pathBuilder(reference)

    //check if shopId in localstorage
    const shopId = sessionStorage.getItem('shopIdStored')
    if (shopId != undefined) {
      navigate(path + shopId + '/')
    } else {
      navigate(path)
    }
  })

  const handleChange = useCallback(
    (value: string[]) => {
      setValue('reference', value, {
        shouldValidate: !!errors.reference?.length,
      })
    },
    [errors]
  )

  const isMobile = useMedia(`(max-width: ${theme.breakpoints.md})`)
  const { ref, translateY } = useSticky<HTMLInputElement>({ marginBottom: 10 })

  useEffect(() => {
    if (!ref.current) {
      return
    }

    ref.current.style.zIndex = '1'

    if (translateY !== 0 && isMobile) {
      ref.current.style.transform = `translateY(${translateY}px)`
      ref.current.style.boxShadow = '0px 4px 4px rgb(0 0 0 / 25%)'
    } else {
      ref.current.style.transform = `inherit`
      ref.current.style.boxShadow = 'inherit'
    }
  }, [ref, translateY, isMobile])

  const title = 'Je veux réparer mon ' + pageContext.modeleName + ' chez Save ! '

  return (
    <>
      <Helmet>
        <meta
          name="description"
          title={`Je veux réparer mon ${capitalizeFirstLetter(
            pageContext.brandName
          )} ${replacePlusWithWord(pageContext.modeleName)} chez Save !  `}
          content={`Votre ${capitalizeFirstLetter(
            pageContext.brandName
          )} ${replacePlusWithWord(
            pageContext.modeleName
          )} est cassé ? Bénéficiez d'une réparation immédiate réalisée par nos experts agréés, pour changer un écran cassé ou pour une batterie épuisée.`}
        />
      </Helmet>

      <RepairContent>
        <RepairContent.Card
          index={3}
          title={
            <RepairContent.SeoHeader
              title={title}
              subtitle="Quel est mon problème ?"
            />
          }
        >
          <div style={{ margin: '0 40px' }}>
            <RepairContent.CardRow>
              <RepairSummary.Card
                avatar={<RepairSummary.PhoneAvatar item={items[0] ?? {}} />}
                title={
                  <>
                    {capitalizeFirstLetterOfWords(pageContext.brandName ?? '')}{' '}
                    <br />
                    {capitalizeFirstLetterOfWords(pageContext.modeleName ?? '')}
                  </>
                }
              ></RepairSummary.Card>
              <RepairContent.QualiReparImg
                alt="RepairQualiRepar"
                src="/images/RepairQualiRepar.png"
              />
            </RepairContent.CardRow>
          </div>
          <form onSubmit={onSubmit}>
            <RepairSelectContainer>
              <ClientOnly>
                <MaxRepSelectionParagraph show={showMessage}>
                  ❗ Nombre maximal de réparations atteint : 3
                </MaxRepSelectionParagraph>
                {pageContext.productType ? (
                  <RepairOtherSelect
                    name={pageContext.modeleName}
                    imageFile={items[0]?.imageFile}
                    pathBuilder={pathBuilder}
                    onChange={handleChange}
                    showMessage={setShowMessage}
                    options={options}
                    productTypeCode={
                      getProductByUrlCode(pageContext.productType)?.code
                    }
                    smartphoneLabel={` ${capitalizeFirstLetterOfWords(
                      pageContext.brandName
                    )} ${pageContext.modeleName}`}
                  ></RepairOtherSelect>
                ) : (
                  <RepairSelect
                    pathBuilder={pathBuilder}
                    onChange={handleChange}
                    showMessage={setShowMessage}
                    options={options}
                    images={{
                      single: {
                        front: (
                          <StaticImage
                            src="../images/RepairSelectFrontSideCropped.png"
                            alt=""
                            quality={100}
                            placeholder="blurred"
                            width={226}
                            height={345}
                          />
                        ),
                        back: (
                          <StaticImage
                            src="../images/RepairSelectBackSideCropped.png"
                            alt=""
                            quality={100}
                            placeholder="blurred"
                            width={226}
                            height={396}
                          />
                        ),
                      },
                      double: (
                        <StaticImage
                          src="../images/RepairSelectDesktopCropped.png"
                          alt=""
                          quality={100}
                          placeholder="blurred"
                          width={336}
                          height={356}
                        />
                      ),
                    }}
                  />
                )}
              </ClientOnly>
              {errors.reference && (
                <Form.Error>{errors.reference.message}</Form.Error>
              )}

              <Form.Submit
                ref={ref}
                value="Valider"
                size="large"
                disabled={isSubmitting}
              />
            </RepairSelectContainer>
          </form>
          <RepairContent.QualiReparInfo padding="20px 80px" />
        </RepairContent.Card>
      </RepairContent>
    </>
  )
}

type IRepair = NonNullable<
  Unpacked<NonNullable<RepairsQuery['allSaveRepair']['nodes']>>
>

export const query = graphql`
  query Repairs($modeleNameRegex: String!, $brandNameRegex: String!) {
    allSaveRepair(
      filter: {
        modeleName: { regex: $modeleNameRegex }
        brandName: { regex: $brandNameRegex }
      }
    ) {
      nodes {
        id
        code
        modeleName
        brandName
        webPricingRepairList {
          label
          reference
          color
          colorHex
          category
        }
        imageFile {
          publicURL
          childImageSharp {
            gatsbyImageData(quality: 100, placeholder: BLURRED, width: 110)
          }
        }
      }
    }
  }
`

export default RepairModelPage
